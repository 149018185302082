import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import CompanyCrossLinking from "./CompanyCrossLinking";
import data from "./MockData";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "company-cross-linking"
    }}>{`Company Cross Linking`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={CompanyCrossLinking} mdxType="Props" />
    <h2 {...{
      "id": "default-companies-list"
    }}>{`Default Companies List`}</h2>
    <ul>
      <li parentName="ul">{`21st Century.`}</li>
      <li parentName="ul">{`Allstate.`}</li>
      <li parentName="ul">{`Ameriprise Financial.`}</li>
      <li parentName="ul">{`Amica.`}</li>
      <li parentName="ul">{`Esurance.`}</li>
      <li parentName="ul">{`Farmers Insurance.`}</li>
      <li parentName="ul">{`GEICO.`}</li>
      <li parentName="ul">{`Liberty Mutual.`}</li>
      <li parentName="ul">{`MetLife.`}</li>
      <li parentName="ul">{`Nationwide.`}</li>
      <li parentName="ul">{`Progressive.`}</li>
      <li parentName="ul">{`State Farm.`}</li>
      <li parentName="ul">{`The General.`}</li>
      <li parentName="ul">{`The Hartford.`}</li>
      <li parentName="ul">{`Travelers.`}</li>
    </ul>
    <h2 {...{
      "id": "default-values-basic-usage"
    }}>{`Default Values Basic Usage`}</h2>
    <Playground __position={1} __code={'<CompanyCrossLinking\n  title=\"Other Cheap Car Insurance Providers\"\n  displayCount={8}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CompanyCrossLinking,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CompanyCrossLinking title="Other Cheap Car Insurance Providers" displayCount={8} mdxType="CompanyCrossLinking" />
    </Playground>
    <h2 {...{
      "id": "custom-data-basic-usage"
    }}>{`Custom Data Basic Usage`}</h2>
    <p>{`Structure defined for `}<strong parentName="p">{`companies`}</strong>{` parameter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`[
  {
    name: 'Progressive',
    url: '/insurance/auto/reviews/progressive/',
    logo: 'Progressive.svg'
  },
  {
    name: 'State Farm',
    url: '/insurance/auto/reviews/state-farm/',
    logo: 'state-farm_vxiugv.png'
  }
]
`}</code></pre>
    <p>{`See below example to use the `}<strong parentName="p">{`companies`}</strong>{` parameter on the component.`}</p>
    <Playground __position={2} __code={'<CompanyCrossLinking\n  title=\"Providers list\"\n  companies={[\n    {\n      name: \'MetLife\',\n      url: \'/insurance/auto/reviews/metlife/\',\n      logo: \'MetLife.png\',\n    },\n    {\n      name: \'Amica\',\n      url: \'/insurance/auto/reviews/amica/\',\n      logo: \'Amica-mutual.jpg\',\n    },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CompanyCrossLinking,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CompanyCrossLinking title="Providers list" companies={[{
        name: 'MetLife',
        url: '/insurance/auto/reviews/metlife/',
        logo: 'MetLife.png'
      }, {
        name: 'Amica',
        url: '/insurance/auto/reviews/amica/',
        logo: 'Amica-mutual.jpg'
      }]} mdxType="CompanyCrossLinking" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      